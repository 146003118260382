import React from "react";
import { graphql, StaticQuery } from "gatsby";
import {
  Navigation,
  Footer,
  CopyrightLabel,
  SiteMap,
  SocialMedia
} from "@fishrmn/fishrmn-components";
import { Link } from "gatsby";
import "semantic-ui-less/semantic.less";
import "./layout.css";

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allFisherman {
          edges {
            node {
              businessName
              socialMedia {
                link
                type
              }
            }
          }
        }
        allFishermanImages {
          edges {
            node {
              heroImages {
                url
              }
              galleryImages {
                url
              }
            }
          }
        }
      }
    `}
    render={queryData => {
      const businessData = queryData.allFisherman.edges[0].node;
      const imageData = queryData.allFishermanImages.edges[0].node;
      const data = { ...businessData, ...imageData };

      return (
        <div className="site-container">
          <div>
            <Navigation
              links={[
                { pageTitle: "Home", slug: "/", internal: true },
                { pageTitle: "Contact", slug: "/contact/", internal: true },
                { pageTitle: "Catering", slug: "/catering/", internal: true },
                {
                  pageTitle: "Menu",
                  slug:
                    "https://fisherman-static.s3.amazonaws.com/public/eightmile/8Mile_Champs_Menu.pdf",
                  internal: false
                },
                {
                  pageTitle: "Order Online",
                  slug: "https://ordering.chownow.com/order/19678/locations",
                  internal: false,
                  className: "chownow-order-online"
                }
              ]}
              backgroundColor={"white"}
              bordered={false}
              centerMobileNavItems={true}
              evenSpacing={false}
              fixed={false}
              header={data.businessName}
              linksAs={"text"}
              logo={false}
              primaryContentPosition={"left"}
              primaryContentCentered={false}
              internalLinkComponent={Link}
            />
          </div>
          <div className="page-container">{children}</div>
          <Footer
            backgroundColor={"primary"}
            horizontalAlign={"center"}
            verticalAlign={"middle"}
            columns={[
              <CopyrightLabel
                company={data.businessName}
                phrase={"All Rights Reserved"}
              />,
              <SiteMap
                links={[
                  { pageTitle: "Home", slug: "/", internal: true },
                  { pageTitle: "Contact", slug: "/contact/", internal: true },
                  { pageTitle: "Catering", slug: "/catering/", internal: true },
                  {
                    pageTitle: "Menu",
                    slug:
                      "https://fisherman-static.s3.amazonaws.com/public/eightmile/8Mile_Champs_Menu.pdf",
                    internal: false
                  },
                  {
                    pageTitle: "Order Online",
                    slug: "",
                    internal: true,
                    className: "chownow-order-online"
                  }
                ]}
                horizontal={true}
                bulleted={false}
                internalLinkComponent={Link}
              />,
              <SocialMedia
                socialMediaValues={data.socialMedia}
                buttonType={"circle"}
                centerHeader={true}
                colors={"secondary"}
                groupButtons={false}
                groupVertically={false}
                header={""}
                inverted={false}
                labelPosition={"right"}
                showLabels={false}
              />
            ]}
          />
        </div>
      );
    }}
  />
);
